<template>
    <admin-dashboard-layout>
        <CustomTable :headers="headers" :items="feedbackItems" />
        <v-row v-if="pag" justify="center">
            <template v-for="(link, i) in pag.links">
                <v-btn
                    v-if="Number.isInteger(link.label)"
                    small
                    text
                    outlined
                    x-small
                    class="mx-1"
                    color="secondary"
                    :disabled="link.active"
                    :key="i"
                    @click="setTokens({ page: link.label })"
                >
                    {{ link.label }}
                </v-btn></template
            >
        </v-row>
    </admin-dashboard-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Feedback",
    components: {
        AdminDashboardLayout: () => import("@/layouts/AdminDashboardLayout"),
    },

    computed: {
        ...mapGetters({
            feedbackItems: "feedback/items",
            pag: "feedback/pagination",
        }),
    },
    methods: {
        ...mapActions({
            setFeedback: "feedback/setItems",
        }),
    },
    created() {
        this.setFeedback();
        this.headers = [
            { text: "Name", value: "name" },
            { text: "Email", value: "email" },
            { text: "Message", value: "message" },
        ];
    },
};
</script>

<style scoped>
</style>
